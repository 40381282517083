
import { defineComponent, ref, PropType, computed } from "vue";
import { Table } from "ant-design-vue";

import { useStore } from "@/store";
import MutationTypes from "@/store/types/mutation-types";

import Filter from "./components/Filter/index.vue";
import Cell from "./components/RowCell/index.vue";

import IDataItem from "@/interfaces/DataItem";
import columns from "./columns";

export default defineComponent({
  components: {
    Table,
    Cell,
    Filter,
  },
  props: {
    items: {
      type: Array as PropType<IDataItem[]>,
      default: [] as PropType<IDataItem[]>,
    },
  },
  setup() {
    const store = useStore();
    const selectedRowKeys = computed<string[]>(
      () => store.getters.getSelectedServices
    );

    const rowSelection = ref({
      onSelect: (record: IDataItem, selected: boolean) => {
        const key = record.key;

        const value = selected
          ? [...selectedRowKeys.value, key]
          : selectedRowKeys.value.filter((item) => item !== key);

        store.commit(MutationTypes.SET_SELECTED_SERVICES, value);
      },
      onSelectAll: (
        selected: boolean,
        selectedRows: IDataItem[],
        changeRows: IDataItem[]
      ) => {
        const keys = changeRows.map((item) => item.key);

        const value = selected
          ? [...selectedRowKeys.value, ...keys]
          : selectedRowKeys.value.filter((item) => !keys.includes(item));

        store.commit(MutationTypes.SET_SELECTED_SERVICES, value);
      },
      getCheckboxProps: (record: IDataItem) => ({
        disabled: ["ENABLING", "DISABLING"].includes(record.state ?? ""),
      }),
      selectedRowKeys,
    });

    return {
      columns,
      rowSelection,
    };
  },
});
