import IDataItem from "@/interfaces/DataItem";
import { Service } from "@/interfaces/ScheduledService";
import { ServiceInfo } from "@/interfaces/ServiceInfo";

export const state = {
  currentTimezone: "UTC",
  currentDateFormat: {
    date: "DD/MM/YYYY",
    time: "hh:mm A",
  },
  environments: [] as { ofoa: string; sap: string }[],
  currentEnvironment: "",
  showActive: false,
  servicesData: {
    inboundItems: [] as IDataItem[],
    outboundItems: [] as IDataItem[],
  },
  selectedServices: [] as string[],
  userDetails: { userName: "", email: "", token: "" },
  scheduledList: [] as Service[],
  servicesInfo: [] as ServiceInfo,
  error: "",
  errorTitle: "Something Went Wrong",
  errorUuid: "",
};

export type State = typeof state;
