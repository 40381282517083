enum ActionTypes {
  UPDATE_SERVICE_STATE_BY_KEY = "UPDATE_SERVICE_STATE_BY_KEY",
  UPDATE_SELECTED_SERVICES_STATE = "UPDATE_SELECTED_SERVICES_STATE",
  UPDATE_SERVICES_STATE = "UPDATE_SERVICES_STATE",
  LOAD_ENVIRONMENTS_INFO = "LOAD_ENVIRONMENTS_INFO",
  LOAD_SERVICES_STATE = "LOAD_SERVICES_STATE",
  LOAD_SERVICES_INFO = "LOAD_SERVICES_INFO",
  LOAD_SCHEDULED_SERVICES = "LOAD_SCHEDULED_SERVICES",
  UPDATE_SCHEDULED_SERVICES = "UPDATE_SCHEDULED_SERVICES",
  CANCEL_SCHEDULED_SERVICE = "CANCEL_SCHEDULED_SERVICE",
  CREATE_SCHEDULED_SERVICE = "CREATE_SCHEDULED_SERVICE",
}

export default ActionTypes;
