const defaultIssuer =
  "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7";

const issuersOverride = {
  prod: "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7",
};

export default {
  oidc: {
    clientId: "nike.mpOrdermgmt.ofoaControlUi",
    issuer: issuersOverride[process.env.VUE_APP_ENV as "prod"] || defaultIssuer,
    redirectUri: `${window.location.origin}/callback`,
    cookies: {
      secure: false,
    },
  },
};
