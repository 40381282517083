import IDataItem from "@/interfaces/DataItem";
import { GeneratedDtoListServiceInfoResponse } from "@/converter/ts_files/list-service-info-response";

export const mapIntoDataItems = (
  servicesState: GeneratedDtoListServiceInfoResponse["services"]
): IDataItem[] =>
  servicesState?.map((service) => {
    const id = service.identifier;

    return {
      key: id,
      service_name: service.name,
      order_type: service.orders,
      state: service.state,
      environment: service.environment,
    } as IDataItem;
  }) ?? ([] as IDataItem[]);
