import { MutationTree } from "vuex";
import cloneDeep from "lodash.clonedeep";

import MutationTypes from "./types/mutation-types";
import { State } from "./state";

import { DataItemState } from "@/interfaces/DataItem";
import { Service } from "@/interfaces/ScheduledService";

export type Mutations<S = State> = {
  [MutationTypes.SET_ENVIRONMENTS](
    state: S,
    environments: { ofoa: string; sap: string }[]
  ): void;

  [MutationTypes.SET_ERROR](state: S, error: string): void;

  [MutationTypes.SET_ERROR_UUID](state: S, errorUuid: string): void;

  [MutationTypes.SET_ERROR_TITLE](state: S, errorUuid: string): void;

  [MutationTypes.SET_CURRENT_ENVIRONMENT](state: S, environment: string): void;

  [MutationTypes.SET_SHOW_ACTIVE](state: S, showActive: boolean): void;

  [MutationTypes.SET_SERVICES_STATE](
    state: S,
    servicesData: State["servicesData"]
  ): void;

  [MutationTypes.SET_SERVICES_INFO](
    state: S,
    servicesInfo: State["servicesInfo"]
  ): void;

  [MutationTypes.UPDATE_SERVICES_STATE](
    state: S,
    payload: { id: string; state: DataItemState }[]
  ): void;

  [MutationTypes.SET_SELECTED_SERVICES](state: S, selectedKeys: string[]): void;

  [MutationTypes.SET_USER_DETAILS](
    state: S,
    payload: State["userDetails"]
  ): void;

  [MutationTypes.SET_USER_SETTINGS](
    state: S,
    payload: {
      timezone: string;
      timeFormat: string;
    }
  ): void;

  [MutationTypes.SET_SCHEDULED_SERVICES](
    state: S,
    payload: State["scheduledList"]
  ): void;

  [MutationTypes.UPDATE_SCHEDULED_SERVICES](
    state: S,
    { index, service }: { index: number; service: Service }
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_ENVIRONMENTS](state, environments) {
    state.environments = environments;
  },

  [MutationTypes.SET_ERROR](state, error) {
    state.error = error;
  },

  [MutationTypes.SET_ERROR_UUID](state, errorUuid) {
    state.errorUuid = errorUuid;
  },

  [MutationTypes.SET_ERROR_TITLE](state, title) {
    state.errorTitle = title;
  },

  [MutationTypes.SET_CURRENT_ENVIRONMENT](state, environment) {
    state.currentEnvironment = environment;
  },

  [MutationTypes.SET_SHOW_ACTIVE](state, showActive) {
    state.showActive = showActive;
  },

  [MutationTypes.SET_SERVICES_STATE](state, servicesData) {
    state.servicesData = cloneDeep(servicesData);
  },

  [MutationTypes.SET_SERVICES_INFO](state, servicesInfo) {
    state.servicesInfo = cloneDeep(servicesInfo);
  },

  [MutationTypes.UPDATE_SERVICES_STATE](state, payload) {
    const keyIndex = payload.reduce(
      (acc, item, index) => ({
        ...acc,
        [item.id]: index,
      }),
      {} as Record<string, number>
    );

    [
      ...state.servicesData.inboundItems,
      ...state.servicesData.outboundItems,
    ].forEach((service) => {
      if (service.key in keyIndex) {
        // eslint-disable-next-line no-param-reassign
        service.state = payload[keyIndex[service.key]].state;
      }
    });
  },

  [MutationTypes.SET_SELECTED_SERVICES](state, selectedKeys) {
    state.selectedServices = selectedKeys;
  },

  [MutationTypes.SET_USER_DETAILS](state, payload) {
    state.userDetails = payload;
  },

  [MutationTypes.SET_USER_SETTINGS](state, { timezone, timeFormat }) {
    state.currentTimezone = timezone;
    state.currentDateFormat.time = timeFormat;
  },

  [MutationTypes.SET_SCHEDULED_SERVICES](state, payload) {
    state.scheduledList = cloneDeep(payload);
  },

  [MutationTypes.UPDATE_SCHEDULED_SERVICES](state, { index, service }) {
    state.scheduledList.splice(index, 1, service);
  },
};
