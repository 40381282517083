
import { PropType, defineComponent } from "vue";
import { CollapsePanel, Checkbox } from "ant-design-vue";

import TableCard from "@/components/Card/TableCard.vue";
import Table from "@/components/Table/index.vue";

import IDataItem from "@/interfaces/DataItem";

export default defineComponent({
  components: {
    CollapsePanel,
    Table,
    TableCard,
    Checkbox,
  },
  props: {
    header: {
      type: String,
      default: "",
    },
    isMobile: Boolean,
    items: {
      type: Array as PropType<IDataItem[]>,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [] as string[],
    };
  },
  computed: {
    isIndeterminate() {
      return (
        this.selected.length > 0 && this.selected.length !== this.items?.length
      );
    },
  },
  methods: {
    onCardClick(key: string) {
      const keyIndex = this.selected.indexOf(key);
      if (keyIndex !== -1) {
        this.selected.splice(keyIndex, 1);
      } else {
        this.selected.push(key);
      }
    },
    onChange() {
      if (this.isIndeterminate || !this.selected?.length) {
        this.selected = this.items?.map(
          ({ service_name }) => service_name
        ) as string[];
      } else {
        this.selected = [];
      }
    },
  },
});
