<template>
  <a-layout-footer class="footer">
    <slot />
  </a-layout-footer>
</template>

<style lang="scss">
footer.footer {
  background-color: $grey-2;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 5px 20px;
}
</style>
