import { computed, onMounted, onUnmounted, ref, ComputedRef } from "vue";

import Breakpoints, {
  widthBreakpoints,
  BreakpointsTypes,
} from "@/interfaces/Breakpoints";

const standardBreakpoints: Breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
};

export default function useBreakpoints({
  xs,
  sm,
  md,
  lg,
}: Breakpoints = standardBreakpoints): {
  width: ComputedRef<number>;
  type: ComputedRef<widthBreakpoints>;
  isMobile: ComputedRef<boolean>;
  isTablet: ComputedRef<boolean>;
} {
  const windowWidth = ref(document.documentElement.clientWidth);

  const onWidthChange = () => {
    windowWidth.value = document.documentElement.clientWidth;
  };
  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));

  const type = computed(() => {
    if (windowWidth.value < xs) return BreakpointsTypes.XS;
    if (windowWidth.value >= xs && windowWidth.value < sm)
      return BreakpointsTypes.SM;
    if (windowWidth.value >= sm && windowWidth.value < md)
      return BreakpointsTypes.MD;
    if (windowWidth.value >= md && windowWidth.value < lg)
      return BreakpointsTypes.LG;
    if (windowWidth.value >= lg) return BreakpointsTypes.XL;
    return null; // This is an unreachable line, simply to keep eslint happy.
  });

  const width = computed(() => windowWidth.value);
  const isMobile = computed(
    () =>
      !!type.value &&
      [BreakpointsTypes.XS, BreakpointsTypes.SM].includes(type.value)
  );
  const isTablet = computed(
    () => !!type.value && type.value === BreakpointsTypes.MD
  );

  return { width, type, isMobile, isTablet };
}
