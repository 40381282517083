import Repository from "@/api/repositories/serviceRepository";
import { mapIntoDataItems } from "@/api/utils";
import { GeneratedDtoListServiceStateResponse } from "@/converter/ts_files/list-service-state-response";

export default {
  getServicesState: async (env: string) => {
    const {
      data: { services: servicesState },
    } = await Repository.getServicesState(env);

    const inboundItems = [] as NonNullable<
      GeneratedDtoListServiceStateResponse["services"]
    >;
    const outboundItems = [] as NonNullable<
      GeneratedDtoListServiceStateResponse["services"]
    >;

    servicesState?.forEach((service) => {
      if (service.type === "INBOUND") {
        inboundItems.push(service);
      }

      if (service.type === "OUTBOUND") {
        outboundItems.push(service);
      }
    });

    return {
      inboundItems: mapIntoDataItems(inboundItems),
      outboundItems: mapIntoDataItems(outboundItems),
    };
  },
};
