import { GetterTree } from "vuex";

import { State } from "./state";

export type Getters = {
  getCurrentTimezone(state: State): string;
  getCurrentDateFormat(state: State): string;
  getCurrentTimeFormat(state: State): string;
  getEnvironments(state: State): { ofoa: string; sap: string }[];
  getCurrentEnvironment(state: State): string;
  getError(state: State): string;
  getErrorTitle(state: State): string;
  getErrorUuid(state: State): string;
  getShowActive(state: State): boolean;
  getServicesData(state: State): State["servicesData"];
  getServicesInfo(state: State): State["servicesInfo"];
  getSelectedServices(state: State): string[];
  getUserDetails(state: State): State["userDetails"];
  getScheduledList(state: State): State["scheduledList"];
};

export const getters: GetterTree<State, State> & Getters = {
  getCurrentTimezone: (state) => state.currentTimezone,
  getCurrentDateFormat: ({ currentDateFormat: { time, date } }) => {
    return `${date} ${time} (z)`;
  },
  getCurrentTimeFormat: (state) => state.currentDateFormat.time,
  getUserDetails: (state) => state.userDetails,
  getEnvironments: (state) => state.environments,
  getError: (state) => state.error,
  getErrorUuid: (state) => state.errorUuid,
  getErrorTitle: (state) => state.errorTitle,
  getCurrentEnvironment: (state) => state.currentEnvironment,
  getShowActive: (state) => state.showActive,
  getServicesData: (state) => state.servicesData,
  getServicesInfo: (state) => state.servicesInfo,
  getSelectedServices: (state) => state.selectedServices,
  getScheduledList: (state) =>
    state.scheduledList.sort(
      (a, b) =>
        new Date(a.scheduled).valueOf() - new Date(b.scheduled).valueOf()
    ),
};
