enum MutationTypes {
  SET_ENVIRONMENTS = "SET_ENVIRONMENTS",
  SET_ERROR = "SET_ERROR",
  SET_ERROR_UUID = "SET_ERROR_UUID",
  SET_ERROR_TITLE = "SET_ERROR_TITLE",
  SET_CURRENT_ENVIRONMENT = "SET_CURRENT_ENVIRONMENT",
  SET_SHOW_ACTIVE = "SET_SHOW_ACTIVE",
  SET_SERVICES_STATE = "SET_SERVICES_STATE",
  SET_SERVICES_INFO = "SET_SERVICES_INFO",
  UPDATE_SERVICES_STATE = "UPDATE_SERVICES_STATE",
  SET_SELECTED_SERVICES = "SET_SELECTED_SERVICES",
  SET_USER_DETAILS = "SET_USER_DETAILS",
  SET_USER_SETTINGS = "SET_USER_SETTINGS",
  SET_SCHEDULED_SERVICES = "SET_SCHEDULED_SERVICES",
  UPDATE_SCHEDULED_SERVICES = "UPDATE_SCHEDULED_SERVICES",
}

export default MutationTypes;
