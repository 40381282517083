import { createApp } from "vue";
import Auth from "@okta/okta-vue";
import { Layout, Menu, Button } from "ant-design-vue";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { store } from "./store/index";
import oktaAuth from "./api/auth";

import "ant-design-vue/dist/antd.less";
import "./styles/main.scss";

const app = createApp(App);

// Main packages
app.use(store);
app.use(router);
app.use(Auth, {
  oktaAuth,
});

// Components
app.use(Layout);
app.use(Menu);
app.use(Button);

app.mount("#app");
