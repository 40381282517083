
import { defineComponent, PropType } from "vue";
import { Row, Col } from "ant-design-vue";
import { mapGetters } from "vuex";
import Card from "@/components/Card/index.vue";
import { Icon } from "@iconify/vue";

import { dateFromISO, getTimezonedDates } from "@/utils/dates";

import { Service } from "@/interfaces/ScheduledService";

export default defineComponent({
  components: {
    Card,
    Row,
    Col,
    Icon,
  },
  props: {
    service: {
      type: Object as PropType<Service>,
      default: () => ({}),
    },
    isCollapsed: Boolean,
    isMobile: Boolean,
  },
  emits: ["toggle", "cancel", "edit"],
  computed: {
    ...mapGetters(["getCurrentTimezone", "getCurrentTimeFormat"]),
    dateFormat() {
      return `MMMM DD, YYYY ${this.getCurrentTimeFormat} (z)`;
    },
    info() {
      return [
        {
          key: "Status",
          value: this.service?.state,
        },
        {
          key: "Scheduled start date",
          value: dateFromISO(
            this.service?.scheduled as string,
            this.getCurrentTimezone,
            this.dateFormat
          ),
        },
        {
          key: "Environment",
          value: this.service?.environment,
        },
        {
          key: "Action",
          value:
            this.service?.action == "ENABLE"
              ? "Enable services"
              : "Disable services",
        },
        {
          key: "Requested by",
          value: this.service?.requester,
        },
        {
          key: "Requested via",
          value: this.service?.originator,
        },
      ];
    },
    times() {
      return getTimezonedDates({
        date: this.service?.scheduled as string,
        dateFormat: `${this.dateFormat} - zz`,
      });
    },
    collapseBtnContent() {
      return this.isCollapsed ? "Expand" : "Collapse";
    },
  },
  methods: {
    collapse() {
      this.$emit("toggle", this.service?.identifier);
    },
  },
});
