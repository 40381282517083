import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StateCell = _resolveComponent("StateCell")!
  const _component_DateCell = _resolveComponent("DateCell")!
  const _component_ActionCell = _resolveComponent("ActionCell")!

  return (_ctx.column?.key === 'state')
    ? (_openBlock(), _createBlock(_component_StateCell, {
        key: 0,
        value: _ctx.record?.state
      }, null, 8, ["value"]))
    : (
      _ctx.column?.key === 'scheduled_whq' || _ctx.column?.key === 'scheduled_local'
    )
      ? (_openBlock(), _createBlock(_component_DateCell, {
          key: 1,
          value: _ctx.record![_ctx.column.key],
          isStopped: _ctx.record?.state === 'DISABLED'
        }, null, 8, ["value", "isStopped"]))
      : (_ctx.column?.key === 'action')
        ? (_openBlock(), _createBlock(_component_ActionCell, {
            key: 2,
            record: _ctx.record
          }, null, 8, ["record"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.record![_ctx.column.key]), 1))
}