import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "headline-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Table = _resolveComponent("Table")!
  const _component_TableCard = _resolveComponent("TableCard")!
  const _component_CollapsePanel = _resolveComponent("CollapsePanel")!

  return (_openBlock(), _createBlock(_component_CollapsePanel, null, _createSlots({
    header: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.header), 1)
    ]),
    default: _withCtx(() => [
      (!_ctx.isMobile)
        ? (_openBlock(), _createBlock(_component_Table, {
            key: 0,
            items: _ctx.items
          }, null, 8, ["items"]))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.items, (item, i) => {
            return (_openBlock(), _createBlock(_component_TableCard, {
              key: `${_ctx.header}_${i}`,
              data: item,
              class: _normalizeClass({
          'mb-4': i !== _ctx.items!.length - 1,
          'card--selected': _ctx.selected.includes(item.service_name as string)
        }),
              onClick: ($event: any) => (_ctx.onCardClick(item.service_name as string))
            }, null, 8, ["data", "class", "onClick"]))
          }), 128))
    ]),
    _: 2
  }, [
    (_ctx.isMobile)
      ? {
          name: "extra",
          fn: _withCtx(() => [
            _createVNode(_component_Checkbox, {
              disabled: !_ctx.items?.length,
              indeterminate: _ctx.isIndeterminate,
              checked: _ctx.selected.length === _ctx.items?.length,
              onChange: _ctx.onChange,
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, null, 8, ["disabled", "indeterminate", "checked", "onChange"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}