<template>
  <transition name="fadeAway">
    <div v-if="$store.getters.getError" class="card d-flex">
      <p class="red-box"></p>
      <div>
        <h2 class="card-title">{{ $store.getters.getErrorTitle }}</h2>
        <p class="p-main errorMessage">
          {{ $store.getters.getError }}
        </p>
        <div class="card-actions">
          <span class="errorId"
            >Error:
            {{ $store.getters.getErrorUuid }}
          </span>
          <button class="btn-red" aria-label="Error" @click.prevent="close">
            Close
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { store } from "@/store";
import MutationTypes from "@/store/types/mutation-types";

export default {
  methods: {
    close() {
      store.commit(MutationTypes.SET_ERROR, "");
      store.commit(MutationTypes.SET_ERROR_UUID, "");
    },
  },
};
</script>

<style scoped>
.red-box {
  width: 10px;
  background-color: hsl(353, 98%, 41%);
  border-radius: 7px 0 0 7px;
}
.card {
  background: hsl(0, 0%, 100%);
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
  text-align: center;
  font-family: "Helvetica Neue Regular", sans-serif;
  position: fixed;
  right: 50px;
  bottom: 50px;
}

.card-title {
  font-family: "Helvetica Neue", sans-serif;
  text-align: left;
  margin: 0;
  color: hsl(353, 98%, 41%);
  padding: 5px 10px;
  font-size: 1.87em;
  opacity: 1;
  transition: opacity 2s linear;
  min-width: 450px;
}

.card-title::before {
  content: "✖ ";
  color: white;
  text-align: center;
  display: inline-block;
  width: 19px;
  height: 19px;
  -moz-border-radius: 9.5px;
  -webkit-border-radius: 9.5px;
  border-radius: 9.5px;
  background-color: hsl(353, 98%, 41%);
  margin-right: 5px;
}
.p-main {
  padding: 10px;
  text-align: left;
  font-size: 1.3em;
  line-height: 20px;
  font-weight: 200;
}

.card-actions {
  display: flex;
  text-align: right;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  font-size: 1.3em;
  justify-content: space-between;
  height: 2.3em;
  align-items: center;
}

.card-actions > button {
  padding: 0.2rem 1rem;
}

.btn-red {
  background-color: hsl(353, 98%, 41%);
  color: #f3f3f3;
  border-radius: 4px;
  border-color: hsl(353, 98%, 41%);
}

.fadeAway {
  transition: all 0.3s ease;
}

.fadeAway-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fadeAway-leave-to,
.fadeAway-enter {
  opacity: 0;
  transform: translateX(100px);
}

.errorMessage {
  font-size: 1.4em;
}

.errorId {
  padding: 10px;
  text-align: left;
  font-size: 0.9em;
  font-weight: 300;
  color: rgba(119, 103, 103, 0.9);
}
</style>
