import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { navigationGuard, LoginCallback } from "@okta/okta-vue";

import HomePage from "../pages/HomePage.vue";
import SchedulerPage from "../pages/SchedulerPage/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: SchedulerPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/callback",
    name: "Callback",
    component: LoginCallback,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(navigationGuard);

export default router;
