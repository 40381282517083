
import { defineComponent, PropType } from "vue";

import StateCell from "./StateCell.vue";
import DateCell from "./DateCell.vue";
import ActionCell from "./ActionCell.vue";

import IDataItem from "@/interfaces/DataItem";
import IColumn from "@/interfaces/Column";

export default defineComponent({
  components: {
    StateCell,
    DateCell,
    ActionCell,
  },
  props: {
    column: {
      type: Object as PropType<IColumn>,
      required: true,
    },
    record: {
      type: Object as PropType<IDataItem>,
      required: true,
    },
  },
});
