export enum BreakpointsTypes {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
}

export type widthBreakpoints =
  | BreakpointsTypes.XS
  | BreakpointsTypes.SM
  | BreakpointsTypes.MD
  | BreakpointsTypes.LG
  | BreakpointsTypes.XL
  | null;

export default interface Breakpoints extends Record<string, number> {
  xs: number;
  sm: number;
  md: number;
  lg: number;
}
