import IDataItem from "@/interfaces/DataItem";
import IColumn from "@/interfaces/Column";

type FilterFunction = (
  this: string,
  value: string | number | boolean,
  record: IDataItem
) => boolean;

const sorter = function <T extends IDataItem>(
  this: string,
  a: T,
  b: T
): number {
  const keyA: string = a[this];
  const keyB: string = b[this];

  if (keyA < keyB) {
    return -1;
  } else if (keyA > keyB) {
    return 1;
  }
  return 0;
};
const onFilter: FilterFunction = function (value, record) {
  const _value = value as string;
  return record[this].toString().toLowerCase().includes(_value.toLowerCase());
};

const columns: IColumn[] = [
  {
    title: "Service name",
    dataIndex: "service_name",
    key: "service_name",
    customFilterDropdown: true,
    sorter: sorter.bind("service_name"),
    onFilter: onFilter.bind("service_name"),
  },
  {
    title: "Order type",
    dataIndex: "order_type",
    key: "order_type",
    width: "20%",
    customFilterDropdown: true,
    sorter: sorter.bind("order_type"),
    onFilter: onFilter.bind("order_type"),
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
    width: "20%",
    filters: [
      {
        text: "Running",
        value: "ENABLED",
      },
      {
        text: "Stopped",
        value: "DISABLED",
      },
    ],
    sorter: sorter.bind("state"),
    onFilter: (value, record) => record.state.indexOf(value) === 0,
  },
  {
    key: "action",
    title: "Action",
    width: "15%",
    sorter: sorter.bind("state"),
  },
];

export default columns;
