import moment from "moment-timezone";

import { useStore } from "@/store";

const store = useStore();
const abbrs: Record<string, string> = {
  CST: "China Standard Time",
  CDT: "China Daylight Time",
  PST: "Pacific Standard Time",
  PDT: "Pacific Daylight Time",
  CET: "Central European Time",
  CEST: "Central European Summer Time",
  IST: "India Standard Time",
};

moment.fn.zoneName = function () {
  const abbr = this.zoneAbbr();
  return abbrs[abbr] || abbr;
};

const today = moment();
export const TIMEZONES = ["UTC", "CET", "CST6CDT", "PST8PDT", "Asia/Kolkata"];
export const TIMEZONES_LIST = TIMEZONES.map((item) => ({
  value: item,
  label: today.tz(item).format("z"),
}));

export const dateFromISO = (
  date: string,
  timezone: string = store.getters.getCurrentTimezone,
  dateFormat: string = store.getters.getCurrentDateFormat
): string => {
  return moment(date).tz(timezone)?.format(dateFormat);
};

export const getTimezonedDates = ({
  date,
  dateFormat,
  withoutCurrentTz = true,
}: {
  date: string;
  dateFormat?: string;
  withoutCurrentTz?: boolean;
}): string[] => {
  const currentTimezone = store.getters.getCurrentTimezone;
  const timezones = withoutCurrentTz
    ? TIMEZONES.filter((name) => name !== currentTimezone)
    : TIMEZONES;

  return timezones.map((tz) => dateFromISO(date, tz, dateFormat));
};

export const getDefaultTimezones = ():
  | {
      value: string;
      label: string;
    }[]
  | undefined => {
  const today = moment();
  return TIMEZONES.map((item) => ({
    value: item,
    label: `${moment.tz(item).format("z")} (GMT ${today
      .tz(item)
      ?.format("Z")})`,
  }));
};
