export default {
  SERVICES_INFO: "/info/services",
  SERVICES_STATE: "/state/list",
  SERVICES_SCHEDULE_STATE: "/scheduled/list",

  UPDATE_SERVICE_STATE: "/state/action",

  CANCEL_SERVICES_SCHEDULE: "/scheduled/cancel",
  CREATE_SERVICES_SCHEDULE: "/scheduled/create",

  ENV_INFO: "/info/environments",
};
