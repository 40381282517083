import API from "@/api/endpoints";
import httpClient from "@/api/httpClient";
import { GeneratedDtoListServiceStateResponse } from "@/converter/ts_files/list-service-state-response";
import { GeneratedDtoListServiceInfoResponse } from "@/converter/ts_files/list-service-info-response";
import { GeneratedDtoSharedInstantStateUpdateRequest } from "@/converter/ts_files/instant-state-update-request";
import { GeneratedDtoEnvironmentsInfoResponse } from "@/converter/ts_files/environments-info-response";
import { ScheduledResponse } from "@/interfaces/ScheduledService";
import { GeneratedDtoListServiceInfoResponse as ScheduleCreateRequest } from "@/converter/ts_files/schedule-create-request";
import { State } from "@/store/state";
import { ArrayElement } from "@/interfaces/utils";
import { GeneratedDtoScheduleCancelResponse } from "@/converter/ts_files/schedule-cancel-response";
import { GeneratedDtoScheduleDeleteRequest } from "@/converter/ts_files/schedule-cancel-request";

export default {
  getServicesInfo: () =>
    httpClient.get<GeneratedDtoListServiceInfoResponse>(API.SERVICES_INFO),
  getServicesState: (env: string) =>
    httpClient.get<GeneratedDtoListServiceStateResponse>(API.SERVICES_STATE, {
      params: {
        env: env,
      },
    }),
  getServicesScheduleState: (all: boolean, env?: string) =>
    httpClient.get<ScheduledResponse>(API.SERVICES_SCHEDULE_STATE, {
      params: {
        all: String(all),
        env: env ? String(env) : "DEV",
      },
    }),
  getEnvironmentsInfo: () =>
    httpClient.get<GeneratedDtoEnvironmentsInfoResponse>(API.ENV_INFO),
  updateServiceState: (data: GeneratedDtoSharedInstantStateUpdateRequest) =>
    httpClient.post<GeneratedDtoListServiceInfoResponse>(
      API.UPDATE_SERVICE_STATE,
      data
    ),
  cancelOutageService: (data: GeneratedDtoScheduleDeleteRequest) =>
    httpClient.post<GeneratedDtoScheduleCancelResponse>(
      API.CANCEL_SERVICES_SCHEDULE,
      data
    ),
  createServiceOutage: (data: ScheduleCreateRequest) =>
    httpClient.post<ArrayElement<State["scheduledList"]>>(
      API.CREATE_SERVICES_SCHEDULE,
      data
    ),
};
