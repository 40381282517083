import { ActionTree, ActionContext } from "vuex";
import cloneDeep from "lodash.clonedeep";

import Repository from "@/api/repositories/serviceRepository";
import Service from "@/api/services/homeService";

import { State } from "./state";
import { Mutations } from "./mutations";
import { Getters } from "./getters";
import ActionTypes from "./types/action-types";
import MutationTypes from "./types/mutation-types";

import { DataItemState } from "@/interfaces/DataItem";
import { Service as ServiceType } from "@/interfaces/ScheduledService";
import { GeneratedDtoSharedInstantStateUpdateRequest } from "@/converter/ts_files/instant-state-update-request";
import { GeneratedDtoListServiceInfoResponse } from "@/converter/ts_files/schedule-create-request";
import { SCHEDULE_ORIGINATOR } from "@/utils/constant";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & Omit<ActionContext<State, State>, "commit" | "getters">;

type ServiceAction = "ENABLE" | "DISABLE";

export interface Actions {
  [ActionTypes.UPDATE_SERVICES_STATE](
    { commit }: AugmentedActionContext,
    {
      data,
      state,
    }: {
      data: GeneratedDtoSharedInstantStateUpdateRequest;
      state: { id: string; state: DataItemState }[];
    }
  ): void;

  [ActionTypes.UPDATE_SERVICE_STATE_BY_KEY](
    { commit, getters, dispatch }: AugmentedActionContext,
    { action, key }: { action: ServiceAction; key: string }
  ): void;

  [ActionTypes.UPDATE_SELECTED_SERVICES_STATE](
    { commit, getters, dispatch }: AugmentedActionContext,
    action: ServiceAction
  ): void;

  [ActionTypes.LOAD_ENVIRONMENTS_INFO]({
    commit,
  }: AugmentedActionContext): void;

  [ActionTypes.LOAD_SERVICES_STATE](
    { commit }: AugmentedActionContext,
    env: string
  ): void;

  [ActionTypes.LOAD_SERVICES_INFO]({ commit }: AugmentedActionContext): void;

  [ActionTypes.LOAD_SCHEDULED_SERVICES](
    { commit }: AugmentedActionContext,
    schedule: { showAll: boolean; env: string }
  ): void;

  [ActionTypes.UPDATE_SCHEDULED_SERVICES](
    { commit }: AugmentedActionContext,
    service: ServiceType
  ): void;

  [ActionTypes.CANCEL_SCHEDULED_SERVICE](
    { commit, getters }: AugmentedActionContext,
    id: string
  ): void;

  [ActionTypes.CREATE_SCHEDULED_SERVICE](
    { commit, getters }: AugmentedActionContext,
    data: GeneratedDtoListServiceInfoResponse
  ): void;
}

export const actions: ActionTree<State, State> & Actions = {
  async [ActionTypes.UPDATE_SERVICES_STATE]({ commit }, { data, state }) {
    try {
      await Repository.updateServiceState(data);
      commit(MutationTypes.UPDATE_SERVICES_STATE, state);
    } catch (e) {
      console.log(e);
    }
  },

  [ActionTypes.UPDATE_SERVICE_STATE_BY_KEY](
    { commit, getters, dispatch },
    { action, key }
  ) {
    const selectedServices = getters.getSelectedServices;
    const data = {
      services: [
        {
          identifier: key,
          action: action,
        },
      ],
    };

    dispatch(ActionTypes.UPDATE_SERVICES_STATE, {
      data,
      state: [
        {
          id: key,
          state: action === "ENABLE" ? "ENABLING" : "DISABLING",
        },
      ],
    });
    commit(
      MutationTypes.SET_SELECTED_SERVICES,
      selectedServices.filter((item: string) => item !== key)
    );
  },

  [ActionTypes.UPDATE_SELECTED_SERVICES_STATE](
    { commit, getters, dispatch },
    action
  ) {
    const selectedServices = getters.getSelectedServices;

    const data = {
      services: selectedServices.map((key: string) => {
        return {
          identifier: key,
          action,
        };
      }),
    };

    const state = selectedServices.map((key: string) => ({
      id: key,
      state: action === "ENABLE" ? "ENABLING" : "DISABLING",
    }));

    dispatch(ActionTypes.UPDATE_SERVICES_STATE, {
      data,
      state,
    });
    commit(MutationTypes.SET_SELECTED_SERVICES, []);
  },

  async [ActionTypes.LOAD_ENVIRONMENTS_INFO]({ commit }) {
    try {
      const info = await Repository.getEnvironmentsInfo();

      if (info.data.environments) {
        const envNames = info.data.environments.map((item) => {
          return {
            ofoa: item.ofoa,
            sap: item.sap,
          };
        });

        commit(MutationTypes.SET_ENVIRONMENTS, envNames);
        commit(MutationTypes.SET_CURRENT_ENVIRONMENT, envNames[0].ofoa ?? "");
      }
    } catch (error) {
      console.error(error);
    }
  },

  async [ActionTypes.LOAD_SERVICES_STATE]({ commit, getters }) {
    try {
      const env = getters.getCurrentEnvironment;

      const data = await Service.getServicesState(env);
      commit(MutationTypes.SET_SERVICES_STATE, data);
    } catch (error) {
      console.error(error);
    }
  },

  async [ActionTypes.LOAD_SERVICES_INFO]({ commit }) {
    try {
      const {
        data: { services },
      } = await Repository.getServicesInfo();

      commit(MutationTypes.SET_SERVICES_INFO, services);
    } catch (error) {
      console.error(error);
    }
  },

  async [ActionTypes.LOAD_SCHEDULED_SERVICES]({ commit }, state) {
    try {
      const {
        data: { services },
      } = await Repository.getServicesScheduleState(state.showAll, state.env);

      commit(MutationTypes.SET_SCHEDULED_SERVICES, services);
    } catch (error) {
      console.error(error);
    }
  },

  async [ActionTypes.CANCEL_SCHEDULED_SERVICE]({ commit, getters }, id) {
    const canceledServiceIndex = getters.getScheduledList.findIndex(
      ({ identifier }) => identifier === id
    );
    try {
      await Repository.cancelOutageService({ identifier: id });

      const canceledService = cloneDeep(
        getters.getScheduledList[canceledServiceIndex]
      );
      canceledService.state = "CANCELLED";

      commit(MutationTypes.UPDATE_SCHEDULED_SERVICES, {
        index: canceledServiceIndex,
        service: canceledService,
      });
    } catch (e) {
      console.log(e);
    }
  },

  [ActionTypes.UPDATE_SCHEDULED_SERVICES]({ commit, getters }, service) {
    const updatedServiceIndex = getters.getScheduledList.findIndex(
      ({ identifier }) => identifier === service.identifier
    );

    commit(MutationTypes.UPDATE_SCHEDULED_SERVICES, {
      index: updatedServiceIndex,
      service: service,
    });
  },

  async [ActionTypes.CREATE_SCHEDULED_SERVICE]({ getters, commit }, service) {
    const scheduledOutages = getters.getScheduledList;
    try {
      const { data: outage } = await Repository.createServiceOutage({
        ...service,
        originator: SCHEDULE_ORIGINATOR,
      });

      commit(MutationTypes.SET_SCHEDULED_SERVICES, [
        ...scheduledOutages,
        outage,
      ]);
    } catch (error) {
      throw new Error("Failed to create service outage");
    }
  },
};
