import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filter = _resolveComponent("Filter")!
  const _component_Cell = _resolveComponent("Cell")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createBlock(_component_Table, {
    pagination: false,
    columns: _ctx.columns,
    "data-source": _ctx.items,
    "row-selection": _ctx.rowSelection
  }, {
    customFilterDropdown: _withCtx((filterProps) => [
      _createVNode(_component_Filter, _normalizeProps(_guardReactiveProps(filterProps)), null, 16)
    ]),
    bodyCell: _withCtx(({ column, record }) => [
      _createVNode(_component_Cell, {
        column: column,
        record: record
      }, null, 8, ["column", "record"])
    ]),
    _: 1
  }, 8, ["columns", "data-source", "row-selection"]))
}