import axios from "axios";
import MockAdapter from "axios-mock-adapter";

import API from "@/api/endpoints";
import { GeneratedDtoListServiceInfoResponse } from "@/converter/ts_files/list-service-info-response";
import { GeneratedDtoListServiceStateResponse } from "@/converter/ts_files/list-service-state-response";
import { GeneratedDtoListScheduleStateResponse } from "@/converter/ts_files/list-schedule-state-response";
import { GeneratedDtoEnvironmentsInfoResponse } from "@/converter/ts_files/environments-info-response";

import {
  generatedDtoEnvironmentsInfoResponse,
  generatedDtoListScheduleStateResponse,
  generatedDtoListServiceInfoResponse,
  generatedDtoListServiceStateResponse,
} from "@/mocks/services";
import { ACCESS_TOKEN } from "@/utils/constant";
import oktaAuth from "@/api/auth";
import { store } from "@/store";
import MutationTypes from "@/store/types/mutation-types";
import { Error } from "@/interfaces/Error";

const host = process.env.VUE_APP_USE_API_HOST;

export const httpClient = axios.create({
  baseURL: host,
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (config.headers) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

httpClient.interceptors.response.use(
  (config) => config,
  (config) => {
    if (!config.response.ok) {
      const error = config.response as Error;
      store.commit(MutationTypes.SET_ERROR, error.data?.message);
      store.commit(MutationTypes.SET_ERROR_UUID, error.data?.uuid);
    }

    if (config.response.status === 401) {
      oktaAuth.signOut();
    }

    return config;
  }
);

if (process.env.VUE_APP_USE_MOCK_SERVER === "true") {
  const mock = new MockAdapter(httpClient, { delayResponse: 200 });

  mock
    .onGet(API.SERVICES_INFO)
    .reply<GeneratedDtoListServiceInfoResponse>(
      200,
      generatedDtoListServiceInfoResponse
    );

  mock
    .onGet(API.SERVICES_STATE)
    .reply<GeneratedDtoListServiceStateResponse>(
      200,
      generatedDtoListServiceStateResponse
    );

  mock
    .onGet(API.SERVICES_SCHEDULE_STATE)
    .reply<GeneratedDtoListScheduleStateResponse>(
      200,
      generatedDtoListScheduleStateResponse
    );

  mock
    .onGet(API.ENV_INFO)
    .reply<GeneratedDtoEnvironmentsInfoResponse>(
      200,
      generatedDtoEnvironmentsInfoResponse
    );

  mock
    .onPost(API.UPDATE_SERVICE_STATE)
    .reply<GeneratedDtoEnvironmentsInfoResponse>(200);
}

export default httpClient;
