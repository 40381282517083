export const range = (start: number, end: number): number[] => {
  const result = [];

  for (let i = start; i < end; i++) {
    result.push(i);
  }

  return result;
};

export const isEmpty = (obj: Record<string, unknown>) => {
  return Object.keys(obj).length === 0;
};
